<template>
  <v-expand-transition>
    <div v-show="expandComment">
      <v-row class="d-flex pt-5 flex-nowrap align-center">
        <avatar
          :border-type="columnType"
          :avatar-type="columnType"
          :url="currentUser.photo_url"
        />
        <v-spacer />
        <v-textarea
          rows="1"
          auto-grow
          solo
          flat
          background-color="textInputPost"
          class="comment-form"
          :placeholder="this.$t('components.post_comments.write_a_comment')"
          @click="writeComment"
        />
        <v-spacer />
        <send-post-btn
          :button-color="btnStyle"
        />
      </v-row>
    </div>
  </v-expand-transition>
</template>

<script>
import { capitalize } from '@/utils';
import trackEvent from 'mixpanel-browser';
import { logEvent } from '../../plugins/firebase';

export default {
  name: 'PostComments',
  components: {
    Avatar: () => import('@/components/globals/Avatar.vue'),
    SendPostBtn: () => import('@/components/globals/SendPostBtn.vue'),
  },
  props: {
    currentUser: {
      type: Object,
      default: () => {},
    },
    columnType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      btnCollor: '',
      expandComment: false,
    };
  },
  computed: {
    btnStyle() {
      return this.defineBtnStyle();
    },
  },
  methods: {
    translateCategory(category) {
      if (category === 'todo') return 'Tarefas';
      return category === 'improve' ? 'Melhorar' : 'Bom';
    },
    show() {
      this.expandComment = !this.expandComment;
    },
    defineBtnStyle() {
      return `post${capitalize(this.columnType)}`;
    },
    writeComment() {
      // eslint-disable-next-line no-alert
      alert(this.$t('components.post_comments.develop_message'));
      logEvent('tentou_escrever_comentario', { coluna: this.columnType });
      const category = this.translateCategory(this.columnType);
      trackEvent.track('tentou_escrever_comentario', { Categoria: category });
    },
  },
};
</script>

<style lang="scss" scoped>

  .comment-form {
    height: 48px;
    background: $text-input-post 0% 0% no-repeat padding-box;
    border-radius: 8px;

    font: normal normal normal 14px/20px NunitoSemiBold;
    letter-spacing: 0.25px;
    color: $text-color;
  }

  @media screen and (max-width: $md) {
    .comment-form {
      width: 52%;
      font-size: 60%;
    }
  }
</style>
